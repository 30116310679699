export const SENTRY_SCOPE = {
  FINGERPRINTS: {
    METHOD: {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      PATCH: 'PATCH',
      DELETE: 'DELETE',
    },
    API_ERROR: 'api-error',
    PUBLIC_EVENT_ERROR: 'public-event-error',
    DISBURSEMENT_SERVICE: {
      ERROR: 'disbursement-service-error',
      INSIGHTS: {
        LEADS: '/api/disbursement-service/leads/:lead_id/insights',
        LOAN_OFFICER:
          '/api/disbursement-service/loan-officers/:loan_officer_id/insights',
      },
      PAYMENTS_HISTORY:
        '/api/disbursement-service/loan-officers/:loan_officer_id/payments-history',
      TROLLEY_PAYMENT_CONFIG:
        '/api/disbursement-service/loan-officers/:loan_officer_id/trolley-payment-config',
    },
  },
  LOAN_OFFICER_PORTAL: {
    TAGS: {
      FEATURE: 'equity-loan-officer-portal-payments',
    },
  },
  LEAD_SUBMISSION: {},
} as const;
