import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';

import { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Icon,
  Paragraph,
  Radio,
  TextArea,
} from '@hl-portals/ui';

import {
  LO_PORTAL_EVENTS,
  useLoanOfficerPortalEvent,
  useScreenSize,
} from '@hl-portals/hooks';

import { FeedbackSentThankYouMessage } from './FeedbackSentThankYouMessage';

const ERROR_MESSAGE = 'Sorry! Something went wrong. Please try again later.';

type SurveyModalTypes = 'message' | 'multiple-choice';

type SurveyModalProps = {
  type?: SurveyModalTypes;
  title: string;
  loanOfficer?: {
    name?: string;
    email?: string;
  };
  multipleChoiceOptions?: {
    text: string;
    value: string;
  }[];
  goBack: () => void;
};

export const SurveyModal = ({
  title,
  type = 'message',
  loanOfficer,
  multipleChoiceOptions = [],
  goBack,
}: SurveyModalProps): React.ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitSucceeded, setSubmitSucceeded] = useState<boolean>(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const { isMobile } = useScreenSize();
  const { logLoanOfficerPortalEvent } = useLoanOfficerPortalEvent();

  const isMultiSelect =
    type === 'multiple-choice' && multipleChoiceOptions.length > 0;
  const shouldDisable =
    (isMultiSelect && !selectedOption) || (!isMultiSelect && !message);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      try {
        logLoanOfficerPortalEvent({
          event_action: LO_PORTAL_EVENTS.COMPLETED_SURVEY_MODAL,
          event_payload: {
            loan_officer_email: loanOfficer?.email ?? '',
            loan_officer_name: loanOfficer?.name ?? '',
            url: window.location.href,
            timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            device: isMobile ? 'mobile_web' : 'desktop_web',
            survey_type: type,
            message,
            has_multiple_choice_question: isMultiSelect,
            selected_answer: selectedOption,
            lo_portal_survey_follow_up: isChecked,
          },
        });
        setSubmitSucceeded(true);
      } catch (error) {
        setSubmitFailed(true);
        Sentry.captureException(error);
      } finally {
        setIsSubmitting(false);
      }
    }, 600);
  };

  if (submitSucceeded) {
    return <FeedbackSentThankYouMessage goBack={goBack} />;
  }

  return (
    <Box
      width={{ xs: '100%', sm: '773px' }}
      height={{ xs: '100%', md: 'auto' }}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      overflowY="auto"
      overflowX="hidden"
      pt="1.5rem"
      px="1rem"
      position="relative"
    >
      {isMobile && (
        <Box width={{ xs: '100%' }} position="absolute" top="2px" left="1rem">
          <Icon onClick={goBack} type="angleLeft" size={24} />
        </Box>
      )}
      <Box flexDirection="column" width="100%" mt={isMobile ? '2rem' : '0'}>
        <Paragraph flex="1" variant="heading-3" mb="2.5rem" flexWrap="wrap">
          {title}
        </Paragraph>
        {isMultiSelect && (
          <>
            <Box flexDirection="column" gap="1rem" mb="1rem">
              {multipleChoiceOptions.map(({ text, value }) => (
                <Box key={value}>
                  <Radio
                    name={`survey-option-${value}`}
                    value={value}
                    checked={selectedOption === value}
                    onChange={(v) => setSelectedOption(v)}
                  />
                  <Paragraph color="darkBlue" variant="text" ml="0.5rem">
                    {text}
                  </Paragraph>
                </Box>
              ))}
            </Box>
            <Paragraph variant="text-small" mb="0.375rem">
              Additional feedback
            </Paragraph>
          </>
        )}
        <Box flexDirection="column" gap="1rem">
          <TextArea
            autoFocus={!isMobile}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type here..."
            height={{ xs: '180px' }}
            errorMessage={submitFailed ? ERROR_MESSAGE : ''}
            isError={submitFailed}
          />
          <Checkbox
            label={
              isMobile
                ? "I'd like someone to follow up with me about this"
                : 'Follow up with me via email'
            }
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </Box>
        {!isMobile && (
          <Box
            flexDirection="row"
            gap="1.5rem"
            justifyContent="flex-end"
            mt="1rem"
          >
            <Button
              width={{ sm: '106px' }}
              size="large"
              color="tertiary"
              variant="outlined"
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isSubmitting}
              disabled={shouldDisable}
              width={{ xs: '100%', sm: '106px' }}
              size="large"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
      {isMobile && (
        <Box mt="auto" width="100%">
          <Button
            onClick={handleSubmit}
            isLoading={isSubmitting}
            disabled={shouldDisable}
            width={{ xs: '100%', sm: '106px' }}
            size="large"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};
